import * as React from "react";
import { useState } from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import cohorte from "@images/proyectos/cohorte.png";
import smartphone from "@images/proyectos/smartphone.png";
import adorno from "@images/adorno/adorno5.png"

const IndexPage = () => {

  const [select, setselect] = useState(-1)

  return (
    <TemplatePage1
      title={"Ongoing Projects"}
      hero={true}
      bg={"var(--tema4)"}
      tc={"white"}
      adorno={<Adorno />}
      content={(
        <Wrapper >
          <CardContainer>
            <Card>
              <Hexagon bgc={"var(--tema4)"}>
                <img src={cohorte} alt={cohorte} />
              </Hexagon>
              <TextZone>
                <h1 className={select === 1 ? "text-2xl font-bold" : "text-3xl"} onClick={() => { select === 1 ? setselect(-1) : setselect(1) }} >E-cohorts</h1>

                <div className={select === 1 ? "text-2xl text-justify show transition-all" : "hidden"}>
                  <p className={"text-xl"}>Evalaution of the comepetence of the system and the experiencefot the primary affections, in particular the non-transmissible diseases (ENT), like hiper-tension and diabetes. We are using an existent cohort of individuals that are being checked up virtually (through mobile phones) along time.</p>
                </div>

              </TextZone>
            </Card>
            <Card left>
              <Hexagon bgc={"var(--tema4)"}>
                <img src={smartphone} alt={smartphone} />
              </Hexagon>
              <TextZone>
                <h1 className={select === 2 ? "text-2xl font-bold" : "text-3xl"} onClick={() => { select === 2 ? setselect(-1) : setselect(2) }} >People's Voice Survey (PVS)</h1>
                <div className={select === 2 ? "text-2xl text-justify show transition-all" : "hidden"} >
                  <p className={"text-xl"}>Development and test of a fast survey of the performance  of the health care system through phone calls from the perspective of the population  in order to integrate the users voice in the health care planification.</p>
                  <p className={"text-xl"}>
                    This evaluation includes preferences of utilization, expectative of quality, quality of attention, trust on health system and the results. This was taken in four countries of LAC: México, Perú, Uruguay y Colombia.
                  </p>
                </div>

              </TextZone>
            </Card>
          </CardContainer>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage



const Hexagon = styled.div`
position: relative;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-color: ${props => props.bgc ? props.bgc : ""};
background-size: cover;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;

@media only screen and (min-width:768px){
  height: 150px;
max-height:150px;
min-height:150px;
width: 150px;
max-width:150px;
min-width:150px;

}
height: 90px;
max-height:90px;
min-height:90px;
width: 90px;
max-width:90px;
min-width:90px;
img{
  height:70%;
}
`

const Wrapper = styled.div`
padding-top: 1rem;
`;

const CardContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`

const Card = styled.div`
border: solid 1px var(--tema4);
background-color:white;
border-radius: 20px;
display: flex;
flex-direction: ${props => props.left ? "row-reverse" : "row"};
justify-content: flex-start;
align-items: center;
padding: 2rem 1rem 2rem 1rem;
margin: 1rem 0 1rem 0;
width: 100%;
`;

const TextZone = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding:15px;
color: var(--tema4);
transition:all 1s;
will-change: contents;


h1{
  margin: 0.2rem 0 0.5rem 0;
  text-align: center;
  cursor: pointer;
}

.ownsmall h1{
  font-size:1rem;
}
.ownbig h1{
  font-size:2rem;
}


div{
  .ownshow{
    display: block;
  }
  .ownhidden{
    display: none;
  }
}
`;

const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 1100px;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
  left: -450px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;
left: -450px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;